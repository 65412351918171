@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Oswald:wght@400;700&display=swap');

//fonts
$Cardo: 'Cardo', serif;
$Oswald: 'Oswald', sans-serif;


//colors

$blue: #181d38;
$light-gray: #eaebf5;

:root {
  $purple: #7664ff;
  $light-purple: #bdadfc;
  $dark-purple: #77298c;
  $pink: #f1ccfa;
  $dark-gray: #b2b8e8;

  $rose: #f1468e;
  $light-rose: #df90bd;
  $bg: rgb(255, 201, 169);
  $white: #ffffff;
  $black: #000000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: $light-gray;
  font-size: 1.6rem;
  overflow-x: hidden;
  font-family: 'Cardo', serif !important;
  background-color: $blue;

}

.landingPage {
  color: $light-gray;
  font-family: 'Cardo', serif !important;
  background-color: $blue;

}

a {
  text-decoration: none;
  color: inherit;
}


ul {
  list-style: none;
}

.width {
  width: min(90%, 1300px);
  margin-inline: auto;
}

/**Hero SVG **********************/

.left {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  animation: left 3.5s ease-in-out 5s forwards;
  z-index: 20;
}

.svg-wrapper-fixed {
  width: 100%;
  height: 80%;
  transform: translate(0%, 36%);
  animation: svgWrapper 3.5s ease-in-out 5s forwards;

  @media screen and (max-width: 868px) {
    height: fit-content;
    transform: translate(0%, 80%);
  }
}

@keyframes left {
  0% {
    width: 100%;
    position: fixed;

  }

  50% {
    width: 50%;
  }

  100% {
    width: 50%;
    position: static;
  }
}

@keyframes svgWrapper {
  to {
    transform: translate(0, -20%);
  }
}

@media screen and (max-width: 868px) {
  @keyframes left {
    0% {
      width: 100%;
      position: fixed;
    }

    50% {
      width: 100%;
    }

    100% {
      width: 100%;
      position: static;
    }
  }

  @keyframes svgWrapper {
    to {
      transform: translate(0, 0);
    }
  }
}

.svg-wrapper-fixed svg {
  height: 100%;
  width: 100%;
}

.text--line {
  font-size: 10vw;
  font-weight: 600;
  animation: textSize 2.2s ease-in-out 5s forwards;
}

@keyframes textSize {
  to {
    font-size: 35px;
  }
}

#outline {
  fill: none;
  stroke: #eaebf5;
  stroke-dasharray: 100%;
  stroke-dashoffset: 0;
  animation-name: outline;
  animation-duration: 4s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

#fill {
  fill-opacity: 0;
  fill: #eaebf5;
  animation-name: fill;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

@keyframes outline {
  from {
    stroke-dashoffset: 100%;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill-opacity: 0;
  }

  to {
    fill-opacity: 1;
  }
}

.stripe1 {
  animation: stripeOne 30s ease-in-out infinite;
}

@keyframes stripeOne {
  50% {
    transform: translateY(-116%);
  }
}

.stripe2 {
  animation: stripeTwo 30s ease-in-out infinite;
  transform: translateY(-116%);
}

@keyframes stripeTwo {
  50% {
    transform: translateY(0);
  }
}

nav ul li a {
  position: relative;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

nav ul li a::before {
  position: absolute;
  content: "";
  left: -100%;
  top: 50%;
  width: 0;
  height: 3px;
  background-color: #eaebf5;
  transition: all 0.5s ease-in-out;
}

nav ul li a:hover:before {
  left: 0;
  width: 100%;
}

.menu {
  transform: scale(0);
}

.fixed-parent-id {
  transform: scale(0);
}

.scale {
  transform: scale(1);
}

.close {
  display: none;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.call-button::before,
.parent-id-button::before,
.sumbmit-parent-id-btn::before,
.feature-cta::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.call-button::before {
  background-color: #eaebf5;

}

.parent-id-button::before {
  background-color: #06b6d4;

}

.sumbmit-parent-id-btn::before {
  background-color: #181d38;

}

.feature-cta::before {
  background-color: #eaebf5;

}

.call-button:hover:before,
.parent-id-button:hover:before,
.sumbmit-parent-id-btn:hover::before,
.feature-cta:hover::before {
  left: 0;
}