.loader {
  background: linear-gradient(90deg,
      #012F6B,
      #019ae7,
      #01204a,
      #093670,
      #001735);
  background-size: 600% 600%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: neonRotate 10s linear infinite;
  position: relative;
}

.circleMiddle {
  background-color: #F5F7FF;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 99;
}

.worm {
  position: absolute;
  width: 20px;
  height: 105px;
  background-color: #F5F7FF;
  border-radius: 5px;
  animation: rotateWorm 4s linear infinite;
  z-index: 80;
}

@keyframes neonRotate {

  0%,
  100% {
    background-position: 0% 0%;
  }

  25%,
  75% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 0% 100%;
  }
}

@keyframes rotateWorm {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}